// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button--tnlWM {\n    padding: 8px;\n    box-shadow: 0px 2px 1px -1px black;\n    border-radius: 4px;\n    user-select: none;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n}\n\n.button--tnlWM:hover {\n    cursor: pointer;\n}\n\n.icon--8pU\\+I {\n    width: 24px;\n    height: 24px;\n    padding-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/common/Button/Button.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kCAAkC;IAClC,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".button {\n    padding: 8px;\n    box-shadow: 0px 2px 1px -1px black;\n    border-radius: 4px;\n    user-select: none;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n}\n\n.button:hover {\n    cursor: pointer;\n}\n\n.icon {\n    width: 24px;\n    height: 24px;\n    padding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button--tnlWM",
	"icon": "icon--8pU+I"
};
export default ___CSS_LOADER_EXPORT___;
