// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".options-container---OtUN {\n    width: 100%;\n}\n\n.option-container--99GsD:not(:last-child) {\n    margin-bottom: 8px;\n}\n\n.options-text--nVJdy {\n    font-size: 24px;\n    padding: 8px 0px;\n}\n\n.left-align--OsnHn {\n    align-self: flex-start;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/ToolOptions/ToolOptions.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".options-container {\n    width: 100%;\n}\n\n.option-container:not(:last-child) {\n    margin-bottom: 8px;\n}\n\n.options-text {\n    font-size: 24px;\n    padding: 8px 0px;\n}\n\n.left-align {\n    align-self: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options-container": "options-container---OtUN",
	"optionsContainer": "options-container---OtUN",
	"option-container": "option-container--99GsD",
	"optionContainer": "option-container--99GsD",
	"options-text": "options-text--nVJdy",
	"optionsText": "options-text--nVJdy",
	"left-align": "left-align--OsnHn",
	"leftAlign": "left-align--OsnHn"
};
export default ___CSS_LOADER_EXPORT___;
