// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".course-card-paper--hXM0h {\n    background-color: white;\n    border-radius: 8px;\n    padding: 8px;\n    margin-top: 8px;\n    min-height: 120px;\n    display: flex;\n}\n\n.course-card-content--slv0l {\n    min-height: 100%;\n    width: 100%;\n    display: grid;\n    grid-template-columns: auto 1fr auto;\n}\n\n.course-card-buttons--nn6tH {\n    margin-left: 8px;\n    align-self: center;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n}\n\n.course-card-buttons--nn6tH > :not(:last-child) {\n    margin-bottom: 4px;\n}\n\n.course-card-image--V18-s {\n    width: auto;\n    height: 150px;\n    max-width: 200px;\n    margin-right: 8px;\n}\n\n.subtitle--MefiE {\n    padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/CardBase/CardBase.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".course-card-paper {\n    background-color: white;\n    border-radius: 8px;\n    padding: 8px;\n    margin-top: 8px;\n    min-height: 120px;\n    display: flex;\n}\n\n.course-card-content {\n    min-height: 100%;\n    width: 100%;\n    display: grid;\n    grid-template-columns: auto 1fr auto;\n}\n\n.course-card-buttons {\n    margin-left: 8px;\n    align-self: center;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n}\n\n.course-card-buttons > :not(:last-child) {\n    margin-bottom: 4px;\n}\n\n.course-card-image {\n    width: auto;\n    height: 150px;\n    max-width: 200px;\n    margin-right: 8px;\n}\n\n.subtitle {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"course-card-paper": "course-card-paper--hXM0h",
	"courseCardPaper": "course-card-paper--hXM0h",
	"course-card-content": "course-card-content--slv0l",
	"courseCardContent": "course-card-content--slv0l",
	"course-card-buttons": "course-card-buttons--nn6tH",
	"courseCardButtons": "course-card-buttons--nn6tH",
	"course-card-image": "course-card-image--V18-s",
	"courseCardImage": "course-card-image--V18-s",
	"subtitle": "subtitle--MefiE"
};
export default ___CSS_LOADER_EXPORT___;
