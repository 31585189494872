// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-container--LuQfj {\n    display: flex;\n    flex-direction: column;\n    margin-top: 16px;\n}\n\n.form-container--LuQfj > :not(:last-child) {\n    padding: 0 8px;\n    box-sizing: border-box;\n}\n\n.form-section-container--vn3xx {\n    display: flex;\n    flex-direction: column;\n    align-self: center;\n    width: 80%;\n}\n\n.form-section-container--vn3xx > * {\n    margin-bottom: 8px;\n}\n\n.submit-button-container--7ETvO {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin-bottom: 0;\n    margin-top: 8px;\n}\n\n.submit-button-container--7ETvO > :not(:last-child) {\n    padding-right: 8px;\n}\n\n.error-text--7V8Qv {\n    color: #d00;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Form/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".form-container {\n    display: flex;\n    flex-direction: column;\n    margin-top: 16px;\n}\n\n.form-container > :not(:last-child) {\n    padding: 0 8px;\n    box-sizing: border-box;\n}\n\n.form-section-container {\n    display: flex;\n    flex-direction: column;\n    align-self: center;\n    width: 80%;\n}\n\n.form-section-container > * {\n    margin-bottom: 8px;\n}\n\n.submit-button-container {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin-bottom: 0;\n    margin-top: 8px;\n}\n\n.submit-button-container > :not(:last-child) {\n    padding-right: 8px;\n}\n\n.error-text {\n    color: #d00;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-container": "form-container--LuQfj",
	"formContainer": "form-container--LuQfj",
	"form-section-container": "form-section-container--vn3xx",
	"formSectionContainer": "form-section-container--vn3xx",
	"submit-button-container": "submit-button-container--7ETvO",
	"submitButtonContainer": "submit-button-container--7ETvO",
	"error-text": "error-text--7V8Qv",
	"errorText": "error-text--7V8Qv"
};
export default ___CSS_LOADER_EXPORT___;
