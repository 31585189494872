// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tool-picker-container--uOHOZ {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: #eee;\n}\n\n.utility-button-container--tE3\\+p {\n    display: grid;\n    grid-template-columns: repeat(5, min-content);\n    width: min-content;\n    gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/ToolPicker/ToolPicker.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,6CAA6C;IAC7C,kBAAkB;IAClB,SAAS;AACb","sourcesContent":[".tool-picker-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: #eee;\n}\n\n.utility-button-container {\n    display: grid;\n    grid-template-columns: repeat(5, min-content);\n    width: min-content;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tool-picker-container": "tool-picker-container--uOHOZ",
	"toolPickerContainer": "tool-picker-container--uOHOZ",
	"utility-button-container": "utility-button-container--tE3+p",
	"utilityButtonContainer": "utility-button-container--tE3+p"
};
export default ___CSS_LOADER_EXPORT___;
