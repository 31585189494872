// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip-container--81B6u {\n    position: relative;\n}\n\n.tooltip--rXgfD {\n    position: absolute;\n    bottom: calc(100% + 4px);\n    left: 50%;\n    z-index: 5;\n    transform: translateX(-50%);\n    background-color: #000d;\n    white-space: nowrap;\n    padding: 4px 8px;\n    color: #eee;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    max-width: 300px;\n}\n\n.sublabel-text--4Itrp {\n    padding-top: 4px;\n    white-space: normal;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/Tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,wBAAwB;IACxB,SAAS;IACT,UAAU;IACV,2BAA2B;IAC3B,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".tooltip-container {\n    position: relative;\n}\n\n.tooltip {\n    position: absolute;\n    bottom: calc(100% + 4px);\n    left: 50%;\n    z-index: 5;\n    transform: translateX(-50%);\n    background-color: #000d;\n    white-space: nowrap;\n    padding: 4px 8px;\n    color: #eee;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    max-width: 300px;\n}\n\n.sublabel-text {\n    padding-top: 4px;\n    white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-container": "tooltip-container--81B6u",
	"tooltipContainer": "tooltip-container--81B6u",
	"tooltip": "tooltip--rXgfD",
	"sublabel-text": "sublabel-text--4Itrp",
	"sublabelText": "sublabel-text--4Itrp"
};
export default ___CSS_LOADER_EXPORT___;
