// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".canvas-container--bkfHp {\n    display: grid;\n    width: max-content;\n    height: max-content;\n    max-width: 100%;\n    max-height: 100%;\n    user-select: none;\n    overflow: auto;\n}\n\n.image-container--CRlOd {\n    display: inline-block;\n    grid-area: 1 / 1;\n}\n\n.canvas--bMJ9k {\n    grid-area: 1 / 1;\n    width: 100%;\n    height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/SketchCanvas/SketchCanvas.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".canvas-container {\n    display: grid;\n    width: max-content;\n    height: max-content;\n    max-width: 100%;\n    max-height: 100%;\n    user-select: none;\n    overflow: auto;\n}\n\n.image-container {\n    display: inline-block;\n    grid-area: 1 / 1;\n}\n\n.canvas {\n    grid-area: 1 / 1;\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas-container": "canvas-container--bkfHp",
	"canvasContainer": "canvas-container--bkfHp",
	"image-container": "image-container--CRlOd",
	"imageContainer": "image-container--CRlOd",
	"canvas": "canvas--bMJ9k"
};
export default ___CSS_LOADER_EXPORT___;
