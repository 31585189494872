// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".labeling-tool-container--AzkmW {\n    display: flex;\n    width: 100%;\n    height: 100vh;\n    touch-action: none;\n}\n\n.save-button-container--VX7sQ {\n    margin-top: 16px;\n}\n\n.side-padding--acuQO {\n    padding: 0px 8px;\n    width: 100%;\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.toolbox--GAJon {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    overflow-y: auto;\n    min-width: 330px;\n    touch-action: none;\n}\n\n.top-padding--a5jUO {\n    padding-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/LabelingTool.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".labeling-tool-container {\n    display: flex;\n    width: 100%;\n    height: 100vh;\n    touch-action: none;\n}\n\n.save-button-container {\n    margin-top: 16px;\n}\n\n.side-padding {\n    padding: 0px 8px;\n    width: 100%;\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.toolbox {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    overflow-y: auto;\n    min-width: 330px;\n    touch-action: none;\n}\n\n.top-padding {\n    padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labeling-tool-container": "labeling-tool-container--AzkmW",
	"labelingToolContainer": "labeling-tool-container--AzkmW",
	"save-button-container": "save-button-container--VX7sQ",
	"saveButtonContainer": "save-button-container--VX7sQ",
	"side-padding": "side-padding--acuQO",
	"sidePadding": "side-padding--acuQO",
	"toolbox": "toolbox--GAJon",
	"top-padding": "top-padding--a5jUO",
	"topPadding": "top-padding--a5jUO"
};
export default ___CSS_LOADER_EXPORT___;
