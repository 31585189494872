// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".utility-button--mEHbA {\n    opacity: 0.6;\n    height: min-content;\n    width: min-content;\n}\n\n.utility-button--mEHbA:hover {\n    cursor: pointer;\n}\n\n.utility-button-active--ntGze {\n    opacity: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/ToolPicker/UtilityButton/UtilityButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".utility-button {\n    opacity: 0.6;\n    height: min-content;\n    width: min-content;\n}\n\n.utility-button:hover {\n    cursor: pointer;\n}\n\n.utility-button-active {\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"utility-button": "utility-button--mEHbA",
	"utilityButton": "utility-button--mEHbA",
	"utility-button-active": "utility-button-active--ntGze",
	"utilityButtonActive": "utility-button-active--ntGze"
};
export default ___CSS_LOADER_EXPORT___;
