// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dialog-background---mGUZ {\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-color: #000a;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.dialog-hidden--BN3Fh {\n    display: none;\n}\n\n.paper--T7mlM {\n    background-color: white;\n    border-radius: 12px;\n    padding: 8px;\n    max-width: min(40vw, 800px);\n    max-height: 80vh;\n    overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Dialog/Dialog.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;IAC3B,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".dialog-background {\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-color: #000a;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.dialog-hidden {\n    display: none;\n}\n\n.paper {\n    background-color: white;\n    border-radius: 12px;\n    padding: 8px;\n    max-width: min(40vw, 800px);\n    max-height: 80vh;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog-background": "dialog-background---mGUZ",
	"dialogBackground": "dialog-background---mGUZ",
	"dialog-hidden": "dialog-hidden--BN3Fh",
	"dialogHidden": "dialog-hidden--BN3Fh",
	"paper": "paper--T7mlM"
};
export default ___CSS_LOADER_EXPORT___;
