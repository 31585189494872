// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".canvas-container--6t\\+o4 {\n    display: grid;\n    width: max-content;\n    height: max-content;\n    max-width: 100%;\n    max-height: 100%;\n    user-select: none;\n}\n\n.image-container--TrK-o {\n    display: inline-block;\n    grid-area: 1 / 1;\n}\n\n.image--TFYrI {\n    max-width: 25vw;\n    max-height: 25vw;\n    width: auto;\n    height: auto;\n}\n\n.canvas--hzDTC {\n    grid-area: 1 / 1;\n    width: 100%;\n    height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/LabelViewer/LabelViewer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".canvas-container {\n    display: grid;\n    width: max-content;\n    height: max-content;\n    max-width: 100%;\n    max-height: 100%;\n    user-select: none;\n}\n\n.image-container {\n    display: inline-block;\n    grid-area: 1 / 1;\n}\n\n.image {\n    max-width: 25vw;\n    max-height: 25vw;\n    width: auto;\n    height: auto;\n}\n\n.canvas {\n    grid-area: 1 / 1;\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas-container": "canvas-container--6t+o4",
	"canvasContainer": "canvas-container--6t+o4",
	"image-container": "image-container--TrK-o",
	"imageContainer": "image-container--TrK-o",
	"image": "image--TFYrI",
	"canvas": "canvas--hzDTC"
};
export default ___CSS_LOADER_EXPORT___;
