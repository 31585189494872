// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox-container--vt3kc {\n    display: flex;\n    align-items: center;\n}\n\n.checkbox--48qM4 {\n    width: 28px;\n    height: 28px;\n    margin: 0 4px 0 0;\n}\n\n.checkbox-text--H6exf {\n    font-size: 16px;\n    user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/common/OptionCheckbox/OptionCheckbox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".checkbox-container {\n    display: flex;\n    align-items: center;\n}\n\n.checkbox {\n    width: 28px;\n    height: 28px;\n    margin: 0 4px 0 0;\n}\n\n.checkbox-text {\n    font-size: 16px;\n    user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-container": "checkbox-container--vt3kc",
	"checkboxContainer": "checkbox-container--vt3kc",
	"checkbox": "checkbox--48qM4",
	"checkbox-text": "checkbox-text--H6exf",
	"checkboxText": "checkbox-text--H6exf"
};
export default ___CSS_LOADER_EXPORT___;
