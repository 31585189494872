// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".comparison-tool-container--jAxn- {\n    display: grid;\n    justify-content: center;\n    justify-items: center;\n    grid-template-columns: repeat(3, min(30vw, 600px));\n}\n\n.comparison-tool-container--jAxn- > :not(:last-child) {\n    margin-right: 16px;\n}\n\n.header--RnaIW {\n    padding-bottom: 0;\n    text-align: center;\n}\n\n.subheader--JkxMV {\n    text-align: center;\n    color: #444;\n    padding-bottom: 4px;\n}\n\n.accuracy-header--c7RlV {\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LabelingTool/ComparisonTool/ComparisonTool.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,kDAAkD;AACtD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".comparison-tool-container {\n    display: grid;\n    justify-content: center;\n    justify-items: center;\n    grid-template-columns: repeat(3, min(30vw, 600px));\n}\n\n.comparison-tool-container > :not(:last-child) {\n    margin-right: 16px;\n}\n\n.header {\n    padding-bottom: 0;\n    text-align: center;\n}\n\n.subheader {\n    text-align: center;\n    color: #444;\n    padding-bottom: 4px;\n}\n\n.accuracy-header {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comparison-tool-container": "comparison-tool-container--jAxn-",
	"comparisonToolContainer": "comparison-tool-container--jAxn-",
	"header": "header--RnaIW",
	"subheader": "subheader--JkxMV",
	"accuracy-header": "accuracy-header--c7RlV",
	"accuracyHeader": "accuracy-header--c7RlV"
};
export default ___CSS_LOADER_EXPORT___;
